<script lang="ts">
export default {
	name: "App",
};
</script>

<template>
	<div id="app">
		<router-view />
	</div>
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #5d5d5d;
	margin-top: 60px;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.25rem;
}

a {
	color:#76A22F;
}

a:hover, a:focus {
	text-decoration: underline;
}

p {
	line-height: 1.5;
}

.step-text ul {
	margin-top: 0.25rem;
	margin-left: 1.5rem;
	list-style: disc;
}

.step-text li {
	font-size: 0.875rem;
	padding: 0;
	margin: 0 0 0 .5rem;
}

.is-hidden {
	/* display: none !important; */
}
.single-step {
	opacity: 1;
}

.single-step.is-hidden {
	/* opacity: 0;
	transition: opacity 0.25s ease; */
}

.backlink .chevron {
	font-size: 1.5em;
}

.backlink a {
	color: #76A22F;
	display:block;
	margin: 3em auto 0 auto;
}

.backlink a:hover {
	text-decoration: underline;
}


.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-title {
	text-align: left !important;
	color: #006d8e !important;
	font-weight: 800 !important;
	font-size: 1.5rem;
	margin-top: 0.25rem;
	height: 45px;
	padding: 0;
}

.vue-form-wizard .wizard-icon-circle {
	border-color: #BEBEBE !important;
	border-width: 3px !important;
}

.vue-form-wizard .wizard-nav-pills {
	justify-content: space-between;
}

.vue-form-wizard .wizard-nav-pills>li>a {
	margin: auto;
}

/* Default Wizard Style resets */
.vue-form-wizard .wizard-nav-pills>li.active>a .wizard-icon,
.vue-form-wizard .wizard-nav-pills>li.active>a:focus .wizard-icon {
	color: #BEBEBE !important;
}

.wizard-navigation {
	margin-top: -3.5rem;
}

.wizard-progress-with-circle,
.wizard-nav {
	width: 33.33%;
	margin-left: auto;
}

.wizard-icon-container {
	border-radius: 50% !important;
}

.wizard-icon {
	font-style: normal !important;
}

.vue-form-wizard .wizard-icon-circle[ref=groupchecked] {
	border-color: #76A22F !important;
}

.wizard-icon-circle[ref=groupchecked] {
	background-color: #76A22F !important;
}

.wizard-icon-circle[ref=groupchecked] .wizard-icon {
	color: #FFFFFF !important;
	display: none !important;
}

.wizard-icon-circle[ref=groupchecked]::after {
	content: '';
	height: 25px;
	width: 25px;
	background-image: url('assets/step_checkmark.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 0.35rem;
}

[ref=groupactive] .wizard-icon-circle[ref=groupchecked] i.wizard-icon {
	color: #76A22F !important;
	display: flex !important;
}

li.active[ref=groupactive] .wizard-icon-circle[ref=groupchecked] i.wizard-icon { /* fixes issue where icon would be grey if you went back using nav pills */
	color: #76A22F !important;
}

[ref=groupactive] .wizard-icon-circle[ref=groupchecked]::after {
	display: none;
}

[ref=groupactive] .wizard-icon-circle[ref=groupchecked],
[ref=groupactive] .wizard-icon-circle[ref=groupchecked] .wizard-icon-container{
	background-color: #D2E8AF !important;
	color: #76A22F !important;
}

.vue-form-wizard .wizard-icon-circle.error-status {
	border-color: #D20000 !important;
	background-color: #FFF7F7 !important;
}

.vue-form-wizard .wizard-icon-circle.error-status i.wizard-icon {
	color: #D20000 !important;
}

.vue-form-wizard .wizard-nav-pills>li.active .error-status i.wizard-icon {
	color: #D20000 !important;
}

.wizard-progress-with-circle {
	top: 29px !important;
	background-color: #BEBEBE;
}

.wizard-progress-bar {
	background-color: #76A22F !important;
}

.wizard-tab-content {
	padding-left: 0 !important;
	padding-right: 0 !important;
	position: relative;
}


/*      o  o                          8    .oPYo.  o 8 8    o    o               8
 8      8                             8    8    8    8 8    8    8               8
 8      8 o8 .oooo. .oPYo. oPYo. .oPYo8   o8YooP' o8 8 8   o8oooo8 .oPYo. .oPYo. 8  .o
 8  db  8  8   .dP  .oooo8 8  `' 8    8    8       8 8 8    8    8 .oooo8 8    ' 8oP'
 `b.PY.d'  8  oP'   8    8 8     8    8    8       8 8 8    8    8 8    8 8    . 8 `b.
  `8  8'   8 `Yooo' `YooP8 8     `YooP'    8       8 8 8    8    8 `YooP8 `YooP' 8  `o.
 ::..:..:::..:.....::.....:..:::::.....::::..::::::......:::..:::..:.....::.....:..::...
 :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
 :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.vue-form-wizard .wizard-nav li {
	display: none;
}

.vue-form-wizard .wizard-nav a {
	width: 45px; /* makes it so links only cover the nav circle and do not overflow */
}

.vue-form-wizard .wizard-nav li:nth-child(1),
.vue-form-wizard .wizard-nav li:nth-child(2),
.vue-form-wizard .wizard-nav li:nth-child(3),
.vue-form-wizard .wizard-nav li:nth-child(4),
.vue-form-wizard .wizard-nav li:nth-child(5) {
	display: block;
}

/*::::::::::::::::::::::::::::::::::::::::::::: */

.flex-columns {
	display: flex;
	border: solid 5px #CDDBB8;
}

.step-column {
	background-color: #EEF3E8;
	width: 33.3%;
	padding: 4rem 1.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 490px;
	flex-basis: 33.3%;
	flex-shrink: 0;
	flex-grow: 0;
}

.step-icon {
	width: 100px;
	height: 100px;
	background-color: #006d8e;
	border: solid 5px #D2E8AF;
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.step-icon img {
	color: #FFFFFF;
	/* ALT text on blue background */
}

.step-heading {
	color: #006d8e;
	text-transform: uppercase;
	font-weight: 900 !important;
	font-size: 2rem;
}

.step-heading span {
	font-weight: 300;
	font-size: 1.25rem;
	text-transform: capitalize;
	display: block;
	line-height: 1.5;
}

.form-column {
	padding: 4rem 6rem;
	text-align: left;
	width: 66.67%;
	flex-basis: 66.67%;
	flex-shrink: 0;
	flex-grow: 0;
}

.section-headline {
	color: #006d8e;
	margin-top: 0;
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
}

@media only screen and (max-width: 460px) {
	.section-headline {
		font-size: 1.5em;
	}
}

.page-content p {
	margin-bottom: 1rem;
}

.page-buttons {
	margin-top: 3rem;
	display: flex;
	gap: 1rem;
}

.page-buttons.verticle {
	flex-direction: column;
	gap: 0.5rem;
}

.page-buttons.verticle .page-button {
	width: auto;
	padding: 1.2rem 1.5rem;
	font-size: 1.1rem;
	text-align: left;
}

.page-buttons button.page-button.link::after,
.page-button.link::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.page-buttons button.page-button.link:hover::after,
.page-button.link:hover::after {
	filter: invert();
}

@media only screen and (max-width: 460px) {
	.page-buttons {
		margin-top: 1.5rem;
	}
}

.page-buttons button.page-button,
.page-button {
	color: #4E4E4E;
	background-color: #FFFFFF;
	border: solid 2px #BEBEBE;
	cursor: pointer;
	padding: 0.75rem 1.25rem;
	border-radius: 6px;
	font-size: 1.25rem;
	margin-bottom: 1rem;
	margin-left: 0;
	min-width: 14rem;
	transition: background-color 0.3s ease, border-color 0.3s ease;
}

@media only screen and (max-width: 768px) {
	.page-buttons {
		flex-direction: column;
	}

	.page-button {
		display: block;
		width: 100%;
	}
}

/* @media only screen and (max-width: 767px) {
	.page-button {
		margin-bottom: 1rem;
	}
} */

.page-buttons button.page-button:hover,
.page-buttons button.page-button:focus,
.page-button:hover,
.page-button:focus {
	background-color: #76A22F;
	border-color: #76A22F;
	color: #FFFFFF;
}

.page-button.continue {
	background-color: #006d8e;
	border: solid 2px #006d8e;
	color: #FFFFFF;
}

.wizard-icon-circle {
	height: 45px !important;
	width: 45px !important;
}

.wizard-footer-right .wizard-btn {
	background-color: #006d8e !important;
	border-color: #006d8e !important;
	position: relative;margin-left: 3rem;
	font-size: 1.25rem;
	padding: 0.75rem;
	font-weight: 300;
	border-radius: 6px;
	line-height: 1;
	width: 14rem;
	margin-top: 1rem;
	top: -7rem;
	right: 5rem;
}

input[type=text],
input[type=tel],
select,
input[type=email],
textarea {
	font-size: 1.25rem;
	padding: 0.75rem 1.5rem;
	border: solid 2px #BEBEBE;
	border-radius: 6px;
	background-color: #F9F9F9;
	font-family: Avenir, Helvetica, Arial, sans-serif;
}

textarea {
	resize: vertical;
	height: 200px;
}

select {
	height: 52px !important;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	appearance: none;
	-webkit-appearance: none;
	background-image: url('/public/assets/ico_arrow_down.svg') !important;
	background-repeat: no-repeat !important;
    background-position: right .5em top 50% !important;
    background-size: .5em auto !important;
	margin-bottom: 0 !important;
	cursor: pointer;
	outline: #76A22F;
}

input::placeholder {
	opacity: 0.5;
}

textarea:focus,
input:focus {
	outline: #006d8e;
	border-color: #006d8e;
	background-color: #EEF3E8;
}

label {
	padding-bottom: 0.5rem;
	font-size: 18px;
}

select:focus {
	outline: #006d8e;
	border-color: #006d8e;
	background-color: #EEF3E8;
}


.input-section.small {
	width: 17%;
}

.input-section.medium {
	width: 33%;
}

.input-section.large {
	width: 50%;
}

.input-section.full {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}

.input-section {
	display: flex;
	flex-direction: column;
	justify-content: start;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	position: relative;
}

.input-section.city {
	padding-left: 0;
}

.input-section:first-of-type {
	padding-left: 0;
}

.input-section:last-of-type {
	padding-right: 0;
}

.input-section.error label {
	color: #D20000;
}

.input-section.error input::placeholder {
	color: #D20000;
}

label.error.message {
	font-weight: 300;
	font-size: .7em;
	margin-top: .25em;
	background-color: transparent;
}

.error a {
	color: #D20000;
	font-weight: bold;
}

/* .input-section.error label.error.message {
	margin-top: -1em;
} */


.input-section.error input,
.input-section input.error {
	background-color: #FFF7F7;
	border-color: #D20000;
	outline-color: #D20000;
	color: #D20000;
}

.input-section .error-message, .error {
	color: #D20000;
}

.name-element {
	display: flex;
	align-items: flex-start;
}

.name-element .input-section.large {
	width: 40%;
}

.name-element .input-section.small {
	width: 20%;
}

@media only screen and (max-width: 768px) {
	.name-element {
		display: block;
	}

	.name-element .input-section {
		padding-left: 0;
	}

	.name-element .input-section.large {
		width: 100%;
	}

	.name-element .input-section.small {
		margin-top: 1rem;
		width: 30%;
	}
}

.address-element {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.address-element .input-section {
	margin-bottom: 1.5rem;
}

/* #city {
	text-transform: capitalize;
} */

.element-divider {
	border: none;
	margin-top: 2rem;
	margin-bottom: 2rem;
	height: 2px;
	background-color: #BEBEBE;
}

.input-section .middle-initial {
	text-transform: uppercase;
	text-align: center;
}

.disclaimer {
	font-style: italic;
	font-size: 1rem
}

.toggle-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.conditional-element-toggle button {
	background-color: #D2E8AF;
	border: solid 4px #76A22F;
	color: #76A22F;
	font-weight: 900;
	font-size: 1.25rem;
	border-radius: 50%;
	cursor: pointer;
	width: 44px;
	height: 44px;
	flex-shrink: 0;
	padding: 0;
}

.conditional-element-toggle button.remove-button {
	background-color: #FFF7F7;
	border: solid 4px #D20000;
	color: #D20000;
	font-weight: 900;
	font-size: 1.25rem;
	border-radius: 50%;
	cursor: pointer;
	width: 44px;
	height: 44px;
	flex-shrink: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toggle-line {
	background-color: #DADADA;
	width: 100%;
	height: 2px;
}

.additional-elements-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.additional-elements-button .additional-element-line {
	background-color: #DADADA;
	width: 100%;
	height: 2px;
}

.additional-elements-button button {
	background-color: #D2E8AF;
	border: solid 4px #76A22F;
	color: #76A22F;
	font-weight: 900;
	font-size: 1.25rem;
	border-radius: 50%;
	cursor: pointer;
	width: 44px;
	height: 44px;
	flex-shrink: 0;
	padding: 0;
}

.toggle-text {
	text-align: center;
	color: #BEBEBE;
}

.final-confirmation .group-title {
	color: #006d8e;
	margin-bottom: 1rem;
	font-weight: 700;
	font-size: 1.5rem;
}

.final-confirmation .confirm-group {
	padding-bottom: 2rem;
	margin-bottom: 3rem;
	border-bottom: solid thin #BEBEBE;
}

.final-confirmation .confirm-group:first-of-type {
	margin-top: 3rem;
}

.final-confirmation .confirm-group:nth-last-of-type(2) {
	padding-bottom: 3rem;
}

.final-confirmation .confirm-group:last-of-type {
	padding-bottom: 0rem;
	border-bottom: none;
}

.final-confirmation .collected-data {
	margin-bottom: 1.5rem;
}

.final-confirmation .question {
	text-transform: capitalize;
}

.final-confirmation .answer .input-section {
	margin-bottom: 1.5rem;
}

.final-confirmation .answer .address-element .input-section {
	margin-bottom: 1.5rem;
}

.final-confirmation .hidden-response {
	margin-bottom: 1.5rem;
	text-transform: capitalize;
}

.step-content p {
	margin-bottom: 1rem;
}

/* MOBILE STYLES */

@media only screen and (max-width: 1024px) {
	#app {
		width: auto;
		max-width: unset;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	#app p {
		line-height: 1.2;
	}

	.step-column,
	.form-column {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.wizard-navigation {
		margin-top: 0;
	}

	.wizard-nav.wizard-nav-pills,
	.wizard-progress-with-circle {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.flex-columns {
		flex-direction: column;
	}

	.step-column {
		width: 100%;
		min-height: unset;
		padding: 1.5rem 1.75rem;
	}

	.step-heading {
		margin-bottom: 0;
	}

	.form-column {
		width: 100%;
		padding: 1.75rem;
	}

	.name-element {
		flex-wrap: wrap;
	}

	.name-element .input-section.small {
		padding-right: 0;
	}

	.name-element .input-section.large {
		padding-left: 0;
		margin-top: 1rem;
	}

	.input-section.small {
		width: 33.33%;
	}

	.input-section.medium {
		width: 66.67%;
	}

	.input-section.large {
		width: 100%;
	}
}

@media only screen and (max-width: 400px) {
	.form-column {
		padding: 1.5rem;
	}

	.page-button {
		width: 100%;
		margin-right: 0;
	}
}

/* PDF STYLES */
.vue-html2pdf .pdf-preview button { /* Had to put this here so the close button on the pdf embed viewer would look right */
	padding: 0 !important;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
