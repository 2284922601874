<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},

	data: function() {
		return {
			required : !!this.elementData?.required,
			elementErrors: {
				'email-address' : ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : '')
		}
	},

	methods: {
		applyFilters: function() {
			if ( this.elementData?.hooks['filter'] ) {
				this.fieldValue = this.$filters[ this.elementData.hooks['filter'] ](this.fieldValue, this.elementData);
			}
		},

		emailFormat: function(string) {
			// matches one occurance of "@"
			// matches letters, numbers, dasehs, underscores, and dots (no consecutive) before and after the @
			// matches max 6 letter tld, can include one dots (e.g. .co.uk)
			if (!/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(string.trim())) {
				return false
			} else {
				return true;
			}
		},

		runActions: function() {
			if ( this.elementData?.hooks['action'] ) {
				console.log( "Running Action: ", this.elementData.hooks['action'] );
				if ( ! this.$actions[ this.elementData.hooks['action'] ](this.fieldValue, this.elementData) ) {
					// this.$emit('error', 'Action: ' + this.elementData.hooks['action'] + ' failed.'); // Action should send back an error message
					console.log( "Action failed" );
				}
			}
		},

		updateEmail: function(e) {
			this.fieldValue = e.target.value
			if ( this.fieldValue.length > 0 && this.elementData?.hooks) {
				this.applyFilters();
				this.runActions();
			}
			e.target.value = this.fieldValue;
			this.$emit('input', e.target.value);
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('email-address', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		},

		validate: function(e) {
			let errorOccur = false

			this.emailFormat(this.value);
			if ( !this.emailFormat(this.value) ) {
				errorOccur = true
				this.updateFormErrors('email-address', 'Must be a valid email address');
			}

			return errorOccur;
		}
	}
}
</script>

<template>
	<div class="email-element" :id="elementId">
		<div class="input-section" :class="{ error: elementErrors['email-address']}">
			<label for="email-address">{{ elementData.label }}<required-mark :required="required" /></label>
			<input id="email-address" type="email" :value="value" :data-eid="elementId" :data-rid="repeaterId" placeholder="your@emailaddress.com" @input="updateEmail" :required="required" >
			<label class="error message" for="email-address" v-if="elementErrors['email-address']">{{ elementErrors['email-address'] }}</label>
		</div>
	</div>
</template>

<style>

</style>
