<script>

import PDFSubmission from '../components/PDFSubmission.vue';
import DataElement from '../components/form-elements/DataElement.vue';

export default {
	components: {
		PDFSubmission,
		DataElement
	},

	computed: {
		prettyData() {
			if (process.env.NODE_ENV === 'production') return '';

			return JSON.stringify(this.$formData, null, 2)
			// return process.env.VUE_APP_RESPONDER_URL;
		}
	},

	methods: {
		getTemplate: function() {
			const storedData = JSON.parse(localStorage.getItem("template"));
			return storedData;
		},

		getData: function() {
			const storedData = JSON.parse(localStorage.getItem("data"));
			return storedData;
		},

		getPDF: function() {
			const storedData = JSON.parse(localStorage.getItem("pdfFile"));
			return storedData;
		},
	}

}

</script>

<template>
	<div class="thank-you-confirmation-page">
		<PDFSubmission></PDFSubmission>
		<div class="flex-columns">
			<div class="step-column">
				<div class="column-padding">
					<div class="step-icon">
						<img
							src="/assets/step-icons/ico_check.svg"
							alt="Step Icon"
						>
					</div>
					<h2 class="step-heading">
						Successful<br><span>Submission</span>
					</h2>
				</div>
			</div>
			<div class="form-column">
				<div class="column-padding">
					<h1 class="section-headline">
						Your Form was Successfully Submitted
					</h1>
					<div class="page-content">
						<p>Thank you for completing a {{ $formData['name'] }} form with City Utilities.</p>

						<!-- Selected Submitted Fields -->
						<div v-for="(thankYouElement, index) in $formData.thankYouElements" :key="index">
							<h2 v-if="thankYouElement.elementLabel">{{ thankYouElement.elementLabel }}</h2>
							<div v-if="thankYouElement.text">
								<!-- eslint-disable-next-line vue/no-v-html -->
								<div v-html="thankYouElement.text"></div>
							</div>
							<div v-for="(element, index) in getData()" :key="index">
								<div v-if="index == thankYouElement.elementId">
									<!-- Repeater -->
									<div v-if="thankYouElement.elementType == 'repeater'">
										<div class="inner-element" v-for="(repeaterElement, index) in thankYouElement.repeaterElements" :key="index">
											<div class="thank-you-address" v-if="repeaterElement.elementType == 'AddressElement'">
												<h2 class="singular-heading" v-if="element.length == 1">{{ thankYouElement.singularElementLabel }}</h2>
												<h2 class="plural-heading" v-if="element.length > 1">{{ thankYouElement.pluralElementLabel }}</h2>
												<div class="inner-element" v-for="(innerElement, index) in element" :key="index">
													<div v-for="(piece, index) in innerElement" :key="index">
														<span>{{ piece.streetAddress }}<br></span>
														<span v-if="piece.secondAddress">Address Line 2 (Optional): {{ piece.secondAddress }}<br></span>
														<span>{{ piece.city }} {{ piece.state }}, {{ piece.zip }}</span>
													</div>
												</div>
											</div>
											<div v-if="repeaterElement.type == 'DataElement'" class="inner-element">
												<DataElement :value="repeaterElement.field" :element-data="repeaterElement" />
											</div>
										</div>
									</div>
									<div v-else-if="thankYouElement.elementType == 'DataElement'">
										<DataElement :value="thankYouElement.field" :element-data="thankYouElement" />
									</div>
									<div v-else>
										<h2 class="singular-heading">{{ thankYouElement.singularElementLabel }}</h2>
										{{ element }}
									</div>
								</div>
							</div>
						</div>

						<p>As a reminder, it is the property owner's responsibility to maintain the correct information with our office. If anything changes, you must submit a new form with the updated information.</p>
						<p>
							Thank you,<br>
							City Utilities Customer Support
						</p>
						<button class="back-button" type="button">
							<router-link
								class="back-link"
								:to="'/'"
							>
								Back to Form
							</router-link>
						</button>
					</div>
				</div>
			</div>
		</div>
	<!-- pre style="text-align: left">{{ prettyData }}</pre -->
	</div>
</template>

<style scoped>
.thank-you-confirmation-page {
	width: 100%;
	margin-top: 9rem; /* Adds margin offset to match form since there's no form title or form nav*/
	margin-bottom: 3rem;
}

.back-button {
	background-color: #006d8e;
	border: solid 2px #006d8e;
	border-radius: 6px;
	margin-top: 2rem;
	margin-right: 1.5rem;
	width: 14rem;
	padding: 0.01rem;
	color: #FFFFFF
}

.back-button a {
	color: #FFFFFF;
}

.section-headline {
	margin-bottom: 3rem;
}

.back-link {
	background-color: #006d8e;
	color: #FFFFFF;
	text-decoration: none;
	font-size: 1.25rem;
	padding: 0.75rem;
	display: block;
}

.form-column, .step-column {
	flex-grow: unset;
	flex-shrink: unset;
	padding: 0;
}

.column-padding {
	padding: 4rem 6rem;
}

/* MOBILE STYLES */

@media only screen and (max-width: 1024px) {
	.thank-you-confirmation-page {
		margin-top: 0;
	}
}

@media only screen and (max-width: 768px) {
	.column-padding {
		padding: 3rem;
	}
}

.singular-heading, .plural-heading {
	font-size: 1.25rem;
	font-weight: bold;
    margin: 1.25rem 0 0;
}

.inner-element {
	margin-bottom: 1.5rem;
}

</style>
