<script>

import HiddenElement from '../components/form-elements/HiddenElement.vue';
import TextElement from '../components/form-elements/TextElement.vue';
import TextareaElement from '../components/form-elements/TextareaElement.vue';
import NameElement from '../components/form-elements/NameElement.vue'
import RadioElement from '../components/form-elements/RadioElement.vue';
import AddressElement from '../components/form-elements/AddressElement.vue';
import PhoneElement from '../components/form-elements/PhoneElement.vue';
import EmailElement from '../components/form-elements/EmailElement.vue';
import CheckboxElement from '../components/form-elements/CheckboxElement.vue';
import FileAttachElement from '../components/form-elements/FileAttachElement.vue';
import InputRepeater from '../components/InputRepeater.vue';
import CalendarElement from '../components/form-elements/CalendarElement.vue';
import EitherOrAddressElement from '../components/form-elements/EitherOrAddressElement.vue';
import SelectElement from '../components/form-elements/SelectElement.vue';
import PDFSubmission from '../components/PDFSubmission.vue';
import ModalElement from '../components/ui/ModalElement.vue';
import DividerElement from '../components/ui/DividerElement.vue';
import DataElement from '../components/form-elements/DataElement.vue';

export default {
	components: {
		InputRepeater,
		HiddenElement,
		TextElement,
		TextareaElement,
		NameElement,
		AddressElement,
		PhoneElement,
		RadioElement,
		EmailElement,
		CheckboxElement,
		FileAttachElement,
		PDFSubmission,
		CalendarElement,
		EitherOrAddressElement,
		SelectElement,
		ModalElement,
		DividerElement,
		DataElement,
	},
	data() {
		let data = {};
		let steps = [];
		let activeSteps = [];
		let elementConditionals = {};
		let stepConditionals = {};
		let currentGroup = '';
		let currentStep = 'get-started';
		let currentIndex = 1;
		let currentGroupIndex = 0;
		let stepIndex = {};
		let actions = {};
		let filters = {};

		let si = 0; // step index iterator
		let gi = 1; // group index iterator

		for (const group of this.$formData.groups) {
			for (const step of group.steps) {

				steps.push({
					...step,
					index: si,
					groupIndex: gi,
					groupId: group.id,
					icon: group.icon,
					groupTitle: group.title,
				});
				stepIndex[step.id] = si++; // reverse index -> id lookup

				// step conditionals
				if (step?.conditional) {
					for (const conditional of step.conditional) {
						stepConditionals = {... stepConditionals, ... conditional};
					}
				}

				for (const content of step.content) {
					if (content.elements) {
						for (const element of content.elements) {
							data[element.id] = this.getElementDataType(element.type, element);

							if (element?.conditional) {
								for (const conditional of element.conditional) {
									elementConditionals = {... elementConditionals, ... conditional };
								}
							}
						}
					}
				}

				if (step.hooks) {
					if (step.hooks?.action) actions[step.id] = step.hooks.action;
					if (step.hooks?.filter) filters[step.id] = step.hooks.filter;
				}
			}
			gi++;
		}

		currentStep  = steps[currentIndex-1]?.id;
		currentGroup = steps[currentIndex-1]?.groupId;
		currentGroupIndex = steps[currentIndex-1]?.groupIndex;

		return {
			name: this.$formData['name'],
			steps,
			activeSteps,
			data,
			elementConditionals,
			stepConditionals,
			stepIndex,
			currentIndex,
			currentStep,
			currentGroup,
			currentGroupIndex,
			prevStep: currentStep,
			prevIndex: 0,
			furthestStep: currentStep,
			furthestIndex: 0,
			finishButtonText: "Submit",
			actions: actions,
			filters: filters,
			errorMsg: '',
			errorPage: '',
			initialDraw: true,
			internalNavigation: false,
		}
	},
	computed: {
		prettyData() {
			if (process.env.NODE_ENV === 'production') return '';

			return JSON.stringify(this.data, null, 2)
			// return process.env.VUE_APP_RESPONDER_URL;
		},
		backOneStep() {
			if (this.currentIndex == 0) return '';
			let previous = this.currentIndex - 1;
			while ( previous >= 0 && this.activeSteps[previous].endForm ) {
				previous--;
			}

			let pgId = this.activeSteps[previous]?.id;
			return pgId || '';
		}
	},

	mounted: function() {
		this.$refs.formWizard.validateOnBack = false;
		this.stepShow(this.currentStep);
		this.$nextTick(this.fixWizardPills);
		this.activeSteps = this.getActiveSteps(this.steps);
	},
	updated: function() {
		if ( this.initialDraw ) {
			this.fixWizardPills();
		}
	},
	methods: {

		/*     o        8  o      8          o   o
		8     8        8         8          8
		8     8 .oPYo. 8 o8 .oPYo8 .oPYo.  o8P o8 .oPYo. odYo.
		`b   d' .oooo8 8  8 8    8 .oooo8   8   8 8    8 8' `8
		`b d'  8    8 8  8 8    8 8    8   8   8 8    8 8   8
		`8'   `YooP8 8  8 `YooP' `YooP8   8   8 `YooP' 8   8
		:::..::::.....:..:..:.....::.....:::..::..:.....:..::..
		:::::::::::::::::::::::::::::::::::::::::::::::::::::::
		:::::::::::::::::::::::::::::::::::::::::::::::::::::*/

		stepValidate: function(stepId) {
			const steps = document.querySelectorAll('.single-step');
			if (!steps) return true;
			let focussed = false;
			for (const step of steps) {
				if (step.id == stepId) {
					const inputs = step.querySelectorAll('input, select, textarea');
					const calendars = step.querySelectorAll('.calendar-element');

					if (!inputs) return true;
					let params = {};
					let didErr = false;

					for (const input of inputs) {
						const elementKey = step.id + '-' + input.dataset.eid;
						const repeaterKey = input.dataset.rid ?? 0;
						let formError = '';

						if (input && input.required && input.value == '') {
							if (!focussed) {
								input.focus();
								focussed = true;
							}
							formError = this.titleCaseID( input.id )  + ' is required';
							didErr = true;
						} else {
							formError = '';
						}

						if (input.type == 'radio') {
							if (repeaterKey) {
								const repeaterStepId = input.dataset.rsid;
								const idx = input.dataset.eid.split('-').pop();
								const key = input.dataset.eid.replace('-' + idx, '');

								if (this.data[repeaterStepId][idx][key] == '') {
									formError = this.titleCaseID( key )  + ' is required';
									didErr = true;
								}
							}
						} else {
							params[input.id] = input.value;
						}

						if ( this.$refs[ elementKey ] ) {
							this.$refs[ elementKey ][0].updateFormErrors(input.id, formError);
							const result = this.$refs[ elementKey ][0].validate?.() ?? false;

							if (result) {
								didErr = true;
							}
						} else if ( this.$refs[ repeaterKey ] ) {
							this.$refs[ repeaterKey ][0].updateRepeaterFormErrors( input.dataset.eid, input.id, formError);
							const result = this.$refs[ repeaterKey ][0].validate?.() ?? false;

							if (result) {
								didErr = true;
							}
						} else {
							console.warn( 'No element found for ref: ' + elementKey + ' or ' + repeaterKey )
						}
					}
					// calendar inputs
					for (const calendar of calendars) {
						const calendarId = calendar.id;
						const elementKey = step.id + '-' + calendarId;
						const repeaterKey = calendar.dataset.rid ?? 0;

						let formError = '';
						params[calendarId] = this.data.calendarId;

						if (calendar.classList.contains('required') && this.data[calendarId] == '') {
							if ( !focussed ) {
								calendar.focus();
								focussed = true
							}
							formError = this.titleCaseID( calendarId )  + ' is required';
							didErr = true;
							calendar.classList.add('error');
						} else {
							formError = '';
							calendar.classList.remove('error');
						}

						if (repeaterKey) {
							const repeaterStepId = calendar.dataset.rsid;
							const eid = calendar.dataset.eid;
							const idx = eid.split('-').pop();
							const key = eid.replace('-' + idx, '');

							if (calendar.classList.contains('required') && this.data[repeaterStepId][idx][key] == '') {
								formError = this.titleCaseID( key )  + ' is required';
								didErr = true;
							}
						}


						if ( this.$refs[ elementKey ] ) {
							this.$refs[ elementKey ][0].updateFormErrors(calendar.id, formError);
						} else if ( this.$refs[ repeaterKey ] ) {
							this.$refs[ repeaterKey ][0].updateRepeaterFormErrors( calendar.dataset.eid, calendar.id, formError);
						} else {
							console.warn( 'No element found for ref: ' + elementKey + ' or ' + repeaterKey )
						}
					}
					if ( didErr ) return false;

					// 		_______.___________. _______ .______       __    __    ______     ______    __  ___      _______.
					//     /       |           ||   ____||   _  \     |  |  |  |  /  __  \   /  __  \  |  |/  /     /       |
					//    |   (----`---|  |----`|  |__   |  |_)  |    |  |__|  | |  |  |  | |  |  |  | |  '  /     |   (----`
					//     \   \       |  |     |   __|  |   ___/     |   __   | |  |  |  | |  |  |  | |    <       \   \
					// .----)   |      |  |     |  |____ |  |         |  |  |  | |  `--'  | |  `--'  | |  .  \  .----)   |
					// |_______/       |__|     |_______|| _|         |__|  |__|  \______/   \______/  |__|\__\ |_______/


					let stepaction = this.activeSteps[this.stepIndex[stepId]]?.hooks?.action?.hook
					let customParams = this.activeSteps[this.stepIndex[stepId]]?.hooks?.action?.params;

					if (customParams !== undefined) {
						params = {};

						Object.entries(JSON.parse(JSON.stringify(customParams))).forEach(([key, value]) => {
							params[key] = this.data[value];
						});
					}
					if (stepaction) {
						let result = this.$actions[stepaction](params);
						if (!result.success) {
							this.errorMsg = result.message;
							this.errorPage = this.activeSteps[this.stepIndex[stepId]]?.hooks?.action?.errorPage;
							if (this.errorPage) {
								this.navigateTo( this.errorPage, true );
							}
							return false;
						} else {
							if (result?.data?.field && result?.data?.value) {
								this.data[result.data.field] = result.data.value;
							}
						}
					}

					let stepContent = this.activeSteps[this.stepIndex[stepId]].content;

					if (this.activeSteps[this.stepIndex[stepId]].id == 'final-confirmation') {
						stepContent = [];
						// gets all step content for final confirmation validation
						for (const step of this.activeSteps) {
							stepContent.push(...step.content);
						}
					}

					//  _______  __       _______ .___  ___.  _______ .__   __. .___________.    __    __    ______     ______    __  ___      _______.
					// |   ____||  |     |   ____||   \/   | |   ____||  \ |  | |           |   |  |  |  |  /  __  \   /  __  \  |  |/  /     /       |
					// |  |__   |  |     |  |__   |  \  /  | |  |__   |   \|  | `---|  |----`   |  |__|  | |  |  |  | |  |  |  | |  '  /     |   (----`
					// |   __|  |  |     |   __|  |  |\/|  | |   __|  |  . `  |     |  |        |   __   | |  |  |  | |  |  |  | |    <       \   \
					// |  |____ |  `----.|  |____ |  |  |  | |  |____ |  |\   |     |  |        |  |  |  | |  `--'  | |  `--'  | |  .  \  .----)   |
					// |_______||_______||_______||__|  |__| |_______||__| \__|     |__|        |__|  |__|  \______/   \______/  |__|\__\ |_______/


					for (const content of stepContent) {
						if (content?.elements) {
							// const stepElements = content.elements;
							for (const stepElement of this.getActiveElements(content.elements)) {

							// .______       _______ .______    _______     ___   .___________. _______ .______          __    __    ______     ______    __  ___      _______.
							// |   _  \     |   ____||   _  \  |   ____|   /   \  |           ||   ____||   _  \        |  |  |  |  /  __  \   /  __  \  |  |/  /     /       |
							// |  |_)  |    |  |__   |  |_)  | |  |__     /  ^  \ `---|  |----`|  |__   |  |_)  |       |  |__|  | |  |  |  | |  |  |  | |  '  /     |   (----`
							// |      /     |   __|  |   ___/  |   __|   /  /_\  \    |  |     |   __|  |      /        |   __   | |  |  |  | |  |  |  | |    <       \   \
							// |  |\  \----.|  |____ |  |      |  |____ /  _____  \   |  |     |  |____ |  |\  \----.   |  |  |  | |  `--'  | |  `--'  | |  .  \  .----)   |
							// | _| `._____||_______|| _|      |_______/__/     \__\  |__|     |_______|| _| `._____|   |__|  |__|  \______/   \______/  |__|\__\ |_______/


								if (stepElement?.elements && stepElement.type == 'repeater') {

									const repeaterID = stepElement.id;
									const repeaterFormData = this.data[repeaterID];
									const addedElementId = stepElement.elements[0].id;

									for (const repeaterElement of stepElement?.elements) {
										if (repeaterElement?.hooks) {
											const repeaterElementId = repeaterElement.id;
											const repeaterElementType = repeaterElement.type;
											const stepAction = repeaterElement?.hooks?.action?.hook;
											const errorPage = repeaterElement?.hooks?.action?.errorPage;
											const elementKey = step.id + '-' + stepElement.id;
											if (stepAction) {
												if (repeaterFormData) {
													// for each instance of the element that uas either added by the user or there from the start
													const repeaterParams = {}
													for (const addedElement of repeaterFormData) {
														const currentValue = this.$refs[elementKey][0].value;
														if (repeaterElementType == 'AddressElement') {
															repeaterParams.address = addedElement[addedElementId].streetAddress;
															repeaterParams['second-address'] = addedElement[addedElementId].secondAddress;
															repeaterParams.city = addedElement[addedElementId].city;
															repeaterParams.state = addedElement[addedElementId].state;
															repeaterParams.zip = addedElement[addedElementId].zip;
														}

														let result = this.$actions[stepAction](repeaterParams, currentValue);
														if (!result.success) {
															this.errorMsg = result.message;
															if (errorPage) {
																this.navigateTo( errorPage, true );
															} else if (this.$refs[ elementKey ]) {
																this.$refs[ elementKey ][0].updateFormErrors(stepElement.id, result.message);
															}
															return false;
														}
													}
												}
											}
										}
									}
								}

								//  regular elements
								if (stepElement?.hooks) {
									const stepAction = stepElement?.hooks?.action?.hook;
									const customParams = stepElement?.hooks?.action?.params;
									const errorPage = stepElement?.hooks?.action?.errorPage;
									const elementKey = step.id + '-' + stepElement.id;
									let currentValue = this.$refs[elementKey][0].value;

									if (customParams !== undefined) {
										params = {};

										Object.entries(JSON.parse(JSON.stringify(customParams))).forEach(([key, value]) => {
											params[key] = this.data[value];
										});
									}

									if (stepAction && step.id != 'final-confirmation') {
										let result = this.$actions[stepAction](params, currentValue);
										if (!result.success) {
											this.errorMsg = result.message;
											if (errorPage) {
												this.navigateTo( errorPage, true );
											} else if (this.$refs[ elementKey ]) {
												const message = result?.inlineMessage || result.message;
												this.$refs[ elementKey ][0].updateFormErrors(stepElement.id, message);
											}
											return false;
										}
									}
								}
							}
						}
					}
				}
			}
			return true;
		},

		getDataType: function(elementIndex) {
			let dataType = null;
			for (const dataElement of this.$formData) {
				if (elementIndex == dataElement.id) {
					dataType = dataElement.content;
				}
			}
			return dataType;
		},

		validateAsync: function() {
			return new Promise((resolve, reject) => {
				if ( this.stepValidate(this.currentStep) ){
					const activePill = document.querySelector('.groupactive .checked')
					if (activePill) {
						activePill.classList.remove('error-status')
					}
					resolve(true)
				} else {
					const activePill = document.querySelector('.groupactive .checked')
					if (activePill) {
						activePill.classList.add('error-status')
					}
					reject(this.errorMsg);
				}
			})
		},

		onValidate: function(result, prevIndex) {
			if (!result) {
				console.log('Bad Validation, going to: ' + this.activeSteps[prevIndex]?.id);
			} else {
				this.errorMsg = ''
				// this.$formErrors = {} // TODO: overkill
				if (this.navigateThroughPromise) {
					this.navigateThroughPromiseResolve()
				}
			}
		},

		finalValidate: function(stepId) {
			const finalValidate = this.stepValidate(stepId);

			if (finalValidate) { // if finalValidate was successful
				this.$router.push('/thank-you-confirmation');
			}
		},


		/*      o  o                          8    .oPYo.  o 8 8    o    o               8
		8      8                             8    8    8    8 8    8    8               8
		8      8 o8 .oooo. .oPYo. oPYo. .oPYo8   o8YooP' o8 8 8   o8oooo8 .oPYo. .oPYo. 8  .o
		8  db  8  8   .dP  .oooo8 8  `' 8    8    8       8 8 8    8    8 .oooo8 8    ' 8oP'
		`b.PY.d'  8  oP'   8    8 8     8    8    8       8 8 8    8    8 8    8 8    . 8 `b.
		`8  8'   8 `Yooo' `YooP8 8     `YooP'    8       8 8 8    8    8 `YooP8 `YooP' 8  `o.
		::..:..:::..:.....::.....:..:::::.....::::..::::::......:::..:::..:.....::.....:..::...
		:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
		:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

		fixWizardPills: function() {
			const pills = document.querySelectorAll('.vue-form-wizard .wizard-nav li');
			if (!pills) return;
			for (const pill of pills) {
				if (pill.hasAttribute('ref')) {
					pill.removeAttribute('ref');
					pill.classList.remove('groupactive');
				}
			}
			const wizardCircles = document.querySelectorAll('.wizard-icon-circle');
			if (!wizardCircles) return;
			for (const circle of wizardCircles) {
				if (circle.hasAttribute('ref')) {
					circle.removeAttribute('ref');
				}
			}
			let groupIndex = 0;
			let prevGroupIndex = 0;
			if ( pills.length > 0 ) this.initalDraw = false;
			for (let stepIndex = 0; stepIndex < this.activeSteps.length; stepIndex++) {
				groupIndex = this.activeSteps[stepIndex]?.groupIndex;
				if (groupIndex === prevGroupIndex) continue;
				prevGroupIndex = groupIndex;
				if (pills[groupIndex-1]) {
					if (groupIndex <= this.currentGroupIndex) {
						pills[groupIndex-1]?.querySelector('.wizard-icon-circle').setAttribute('ref', 'groupchecked');
					}
					if (groupIndex == this.currentGroupIndex) {
						pills[groupIndex-1]?.setAttribute('ref', 'groupactive');
						pills[groupIndex-1]?.setAttribute('class', 'groupactive');
					}
					pills[groupIndex-1].onclick = () => {
						this.navigateTo(this.prevStep, true)
						this.navigateTo(this.activeSteps[stepIndex].id, false, true)
					}
				}
			}
		},

		/*:::::::::::::::::::::::::::::::::::::::::::::::*/

		stepReveal: function() {
			const steps = document.querySelectorAll('.single-step');
			if (!steps) return;
			for (const step of steps) {
				step.classList.add('is-hidden');
				// this.$refs[ step.id ]?.updateFormErrors; // TODO: get elements
			}
		},

		stepShow: function(stepId) {
			this.stepReveal();
			this.currentStep = stepId
			this.currentIndex = this.stepIndex[stepId];
			const steps = document.querySelectorAll('.single-step');
			if (!steps) return;
			for (const step of steps) {
				if (step.id == stepId) {
					step.classList.remove('is-hidden');
				}
			}
		},

		navigateToNoValidate: function(buttonPath) {
			this.internalNavigation = true;
			this.currentStep = buttonPath // apparently, this only gets called on success.
			this.$refs.formWizard.changeTab(
				this.$refs.formWizard.activeTabIndex,
				this.stepIndex[buttonPath],
				true
			);
			this.$refs.formWizard.afterTabChange(
				this.$refs.formWizard.activeTabIndex
			);
			this.internalNavigation = false;
		},

		// navigates through every step between the current step and the step specified by buttonPath
		// stops on a step if it fails validation
		navigateThrough: async function(buttonPath) {
			let startIndex = this.stepIndex[this.currentStep];
			let stopIndex = this.stepIndex[buttonPath];
			let prevIndex = startIndex;
			this.navigatingThrough = true
			for(let stepIndex = startIndex; stepIndex <= stopIndex; stepIndex++) {
				this.navigateThroughPromise = new Promise((resolve, reject) => {
					this.navigateThroughPromiseResolve = resolve;
					this.navigateThroughPromiseReject = reject;
				});

				this.$refs.formWizard.beforeTabChange(
					this.$refs.formWizard.activeTabIndex,
					function() { this.navigateToNoValidate(this.activeSteps[stepIndex].id); }.bind(this)
				);

				try {
					await this.navigateThroughPromise;
				} catch (e) {
					break;
				}

				prevIndex = stepIndex;
			}

			this.navigatingThrough = false;
			this.navigateThroughPromise = null;
			// we have to jump back once to get the last step to show up, since it doesn't show while navigating through
			this.navigateTo(this.activeSteps[startIndex].id, true);
			this.navigateTo(this.activeSteps[prevIndex].id);
		},

		navigateTo: function(buttonPath, skipValidation = false, validateAllSteps = false) {
			let newIndex = this.stepIndex[buttonPath];
			if (newIndex <= this.currentIndex) {
				skipValidation = true;
			}

			if (skipValidation) {
				this.navigateToNoValidate(buttonPath);
				return;
			}

			if (validateAllSteps && newIndex > this.currentIndex + 1) {
				if (newIndex <= this.furthestIndex) {
					this.navigateThrough(buttonPath);
				}

				return;
			}

			this.$refs.formWizard.beforeTabChange(
				this.$refs.formWizard.activeTabIndex,
				function() { this.navigateToNoValidate(buttonPath);	}.bind(this)
			);
		},

		openExternalLink: function(url) {
			window.open(url, '_blank');
		},

		onError: function(errorMsg) {
			// TODO: make the correct circle red
			if ( errorMsg ) {
				this.errorMsg = errorMsg
			}

			if (this.navigateThroughPromise && errorMsg != null) {
				this.navigateThroughPromiseReject();
			}
		},

		// called on circle nav click
		onChange: function(prevIndex, nextIndex) {
			// if this didn't come from navigateTo, consider the nextIndex to be a group,
			// and navigate to the first step in that group
			let navigateAfter = null;
			if ( !this.internalNavigation ) {
				let gi = 0;
				let numSteps = 0;
				for (const group of this.$formData.groups) {
					if (gi == nextIndex) {
						navigateAfter = numSteps;
						break;
					}

					numSteps += group.steps.length;
					gi++;
				}
			}

			this.currentIndex = nextIndex;
			this.currentGroup = this.activeSteps[this.currentIndex]?.groupId;
			this.currentGroupIndex = this.activeSteps[this.currentIndex]?.groupIndex;
			this.currentStep = this.activeSteps[this.currentIndex]?.id;

			if (!this.navigatingThrough && (navigateAfter == null || navigateAfter == this.currentIndex)) {
				if (this.currentIndex > this.furthestIndex) {
					this.furthestIndex = this.currentIndex;
					this.furthestStep = this.currentStep;
				}

				this.prevIndex = this.currentIndex;
				this.prevStep = this.currentStep;
				this.stepShow( this.currentStep );
				this.fixWizardPills();
			}
		},

		getElementDataType: function(elementType, element) {
			switch (elementType) {
				case 'NameElement':
					return {
						firstName: '',
						middleInitial: '',
						lastName: ''
					};
				case 'CalendarElement':
					return '';
				case 'HiddenElement':
					return '';
				case 'RadioElement':
					return '';
				case 'CheckboxElement':
					{
						const defaultData = {}
						for (const checkbox of element.checkboxButtons) {
							defaultData[checkbox.id] = false;
						}
						return defaultData;
					}
				case 'PhoneElement':
					return '';
				case 'TextElement':
					return '';
				case 'EmailElement':
					return '';
				case 'FileAttachElement':
					return {
						files: [],
						names: [],
						filePaths: [],
					};
				case 'AddressElement':
					return {
						streetAddress: '',
						secondAddress: '',
						city: '',
						state: '',
						zip: ''
					};
				case 'repeater':
					return [];
				case 'EitherOrAddressElement':
					return {
						streetAddress: '',
						secondAddress: '',
						city: '',
						state: '',
						zip: ''
					};
				case 'SelectElement':
					return '';
				default:
					return undefined;
			}
		},

		getActiveStepsInsideGroup: function(groupSteps) {
			const activeStepsInGroup = [];
			for (const step of groupSteps) {
				for (const activeStep of this.activeSteps) {
					if (activeStep.id == step.id) {
						activeStepsInGroup.push(step)
					} else {
						continue;
					}
				}
			}
			return activeStepsInGroup;
		},

		getActiveSteps: function(steps) {
			let activeSteps = [];
			if (steps) {
				for (const step of steps) {
					if (step.conditional) {
						for (const stepConditionalKey of step.conditional) {
							const stepConditional = this.stepConditionals[Object.keys(stepConditionalKey)[0]];
							if (stepConditional === 'active' ) {
								activeSteps.push(step);
								break;
							}
						}
					} else {
						activeSteps.push(step);
					}
				}
			}
			return activeSteps;
		},

		getActiveElements: function(elements) {
			const activeElements = [];
			if (elements) {
				for (const element of elements) {
					if (element.conditional) {
						for (const elementConditionalKey in this.elementConditionals) {
							const elementConditional = this.elementConditionals[elementConditionalKey];
							if (elementConditional === 'active') {
								activeElements.push(element);
							}
						}
					} else {
						activeElements.push(element);
					}
				}
			}
			return activeElements;
		},

		updateConditional: function(conditionalId, conditionalType, conditionalPair, conditionalValue) {
			if (conditionalId != null && conditionalType != null ) {
				if (conditionalType == 'step') {
					if (this.stepConditionals[conditionalId]) {
						if (this.stepConditionals[conditionalId] === 'inactive') {
							this.stepConditionals[conditionalId] = 'active';
						}
						if (conditionalPair == null && conditionalValue) {
							if (conditionalValue == 'positive') {
								this.stepConditionals[conditionalId] = 'active';
							} else {
								this.stepConditionals[conditionalId] = 'inactive';
							}
						}
					}

					if (conditionalPair != null) {
						if (this.stepConditionals[conditionalPair]) {
							if (this.stepConditionals[conditionalPair] === 'active') {
								this.stepConditionals[conditionalPair] = 'inactive';
							}
						}
					}
				}

				if (conditionalType == 'element') {
					if (this.elementConditionals[conditionalId]) {
						if (this.elementConditionals[conditionalId] === 'inactive') {
							this.elementConditionals[conditionalId] = 'active';
						} else {
							this.elementConditionals[conditionalId] = 'inactive';
						}
					}
				}
			}
			const newActiveSteps = this.getActiveSteps(this.steps);
			const newStepIndex = {};
			let index = 0;
			for (const activeStep of newActiveSteps) {
				newStepIndex[activeStep.id] = index;
				index++;
			}

			this.stepIndex = newStepIndex;
			this.activeSteps = newActiveSteps;
		},

		activeConditional: function(conditionalId, conditionalType) {
			if (conditionalId == null) {
				return true;
			}

			if (conditionalType == 'element') {
				if (this.elementConditionals[conditionalId] === 'active') {
					return true;
				}
			}
		},

		addHandler: function(repeaterElement) {
			const newData = {};

			for (const element of repeaterElement.elements) {
				newData[element.id] = this.getElementDataType(element.type, element);
			}

			this.data[repeaterElement.id].push(newData);
		},

		titleCaseID: function(value) {
			if (!value) return ''
			value = value.toString()
			return value.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
		},

		storeData: function() {
			localStorage.setItem("stepConditionals", JSON.stringify(this.stepConditionals));
			localStorage.setItem("elementConditionals", JSON.stringify(this.elementConditionals));
			// localStorage.setItem("radioConditionals", JSON.stringify(this.radioConditionals));
			localStorage.setItem("data", JSON.stringify(this.data));
			localStorage.setItem("template", JSON.stringify(this.$formData));
		},

		scrollToTop: function() {
			window.scrollTo(0, 0);
		},
	}
}
</script>

<template>
	<div dir="ltr">
		<form enctype="multipart/form-data" novalidate>
			<!-- {{ stepConditionals }}
			{{ elementConditionals }} -->
			<!-- {{ radioConditionals }} -->
			<form-wizard
				ref="formWizard"
				shape="circle"
				:title="name"
				color="transparent"
				subtitle=""
				:finish-button-text="finishButtonText"
				:start-index="0"
				@on-validate="onValidate"
				@on-change="onChange"
				@on-error="onError"
			>
				<tab-content v-for="( step, index ) in activeSteps" :key="index" class="tab-content" :class="step.id" :before-change="validateAsync" >
					<div class="flex-columns">
						<div class="step-column">
							<div>
								<div class="step-icon">
									<img :src="step.icon" alt="Step Icon" >
								</div>
								<h2 class="step-heading">STEP {{ step?.groupIndex }}<br><span>{{ step.groupTitle }}</span></h2>
								<div class="backlink" v-if="backOneStep">
									<a href="#" @click="navigateTo( backOneStep )">
										<span class="chevron">&#8249; </span>Back to {{ titleCaseID( backOneStep ) }}
									</a>
								</div>
							</div>
						</div>
						<div class="form-column">
							<h1 class="section-headline">{{ step.groupTitle }}</h1>
							<div class="single-step" :class="step.id" :id="step.id">
								<h2 v-if="step.title">{{ step.title }}</h2>
								<div v-if="errorMsg">
									<!-- eslint-disable-next-line vue/no-v-html -->
									<span class="error" v-html="errorMsg" />
								</div>
								<div class="step-content" v-if="step.content">
									<!-- eslint-disable-next-line vue/no-v-html -->
									<p class="step-text" v-for="(stepText, index) in step.content" :key="index" v-html="stepText.text"></p>
									<div class="collected-data" v-if="!step.finalPage">
										<div class="step-elements" :data-step="step.id" >
											<div v-for="( stepElements, index ) in step.content" :key="index" class="element" >
												<div v-for="(element, index) in getActiveElements(stepElements.elements)" :key="index" >
													<div v-if="index >= 1 && !element.borderless">
														<hr class="element-divider">
													</div>
													<div>
														<!-- Repeater -->
														<div v-if="element.type == 'repeater'">
															<InputRepeater
																v-model="data[element.id]"
																:element-data="element"
																:repeater-id="`${step.id}-${element.id}`"
																:repeater-step-id="step.id"
																:ref="`${step.id}-${element.id}`"
																@add="addHandler(element)"
															/>
														</div>
														<!-- Modal Element -->
														<div v-else-if="element.type == 'ModalElement'">
															<div v-if="element.borderless" style="height:10px;"></div>
															<ModalElement
																:trigger="element.trigger"
																:body="element.body"
																:title="element.title"
															/>
														</div>
														<!-- Divider Element -->
														<div v-else-if="element.type == 'DividerElement'">
															<DividerElement :text="element.text" />
														</div>
														<!-- Data Element -->
														<div v-else-if="element.type == 'DataElement'">
															<DataElement :value="data[element.field]" />
														</div>
														<!-- Either Or Address Element -->
														<div v-else-if="element.type == 'EitherOrAddressElement'">
															<EitherOrAddressElement
																:all-form-data="data"
																:ref="`${step.id}-${element.id}`"
																:step-id="step.id"
																:is="element.type"
																:element-data="element"
																v-model="data[element.id]"
																@error="onError"
															/>
														</div>
														<div v-else>
															<component
																:all-form-data="data"
																:element-data="element"
																:is="element.type"
																:ref="`${step.id}-${element.id}`"
																v-model="data[element.id]"
																@error="onError"
															/>
														</div>
													</div>
												</div>
												<div class="conditional-element-toggle" v-if="stepElements.conditionalToggle">
													<div class="toggle-button">
														<div class="toggle-line"></div>

														<button
															v-if="!activeConditional(stepElements.conditionalToggle[0].id, 'element')"
															type="button"
															@click="updateConditional(stepElements.conditionalToggle[0].id, 'element' )"
														>
															<img :src="require('../assets/ico_green_plus.svg')">
														</button>
														<button
															v-else type="button"
															class="remove-button"
															@click="updateConditional(stepElements.conditionalToggle[0].id, 'element' )"
														>
															<img :src="require('/public/assets/ico_red_minus.svg')">
														</button>
														<div class="toggle-line"></div>
													</div>
													<p class="toggle-text">
														{{ activeConditional(stepElements.conditionalToggle[0].id, 'element') ? stepElements.conditionalToggle[0].undoText : stepElements.conditionalToggle[0].text }}
													</p>
												</div>
											</div>
											<!-- eslint-disable-next-line vue/no-v-html -->
											<p class="disclaimer" v-for="(dText, dindex) in step.content" :key="dindex + '-disclaimer'" v-html="dText.disclaimer"></p>
											<div v-if="step.pageButtons" class="page-buttons" :class="step.pageButtons.map(e => e?.buttonType).join(' ')" :data-step="step.id">
												<button
													tabindex="0"
													v-for="(button, index) in step.pageButtons"
													:key="index" class="page-button"
													:class="button.buttonType + ' ' + button.buttonPath"
													@click="button.buttonType === 'link'
														? openExternalLink(button.buttonPath)
														:
															(
																updateConditional(button.updateConditional, 'step', button.conditionalPair, button.conditionValue),
																navigateTo(button.buttonPath),
																scrollToTop()
															)
													"
													type="button"
												>
													{{ button.buttonText }}
												</button>
											</div>
										</div>
									</div>
									<div v-else>
										<!-- Final Confirmation -->
										<div class="final-confirmation">
											<div class="confirm-group" v-for="(group, index) in $formData['groups']" :key="index">
												<div v-if="group.id != 'confirmation'">
													<h2 class="group-title">{{ group.title }}</h2>
													<!-- {{ group.steps }}
													<p>Break</p>
													{{ activeSteps }} -->
													<div class="confirm-step" v-for="(originalStep, index) in getActiveStepsInsideGroup(group.steps)" :key="index">
														<!-- {{ originalStep }} -->
														<div v-for="(content, index) in originalStep.content" :key="index">
															<div v-if="content.elements">
																<div v-for="(element, index) in getActiveElements(content.elements)" :key="index">
																	<!-- Repeater -->
																	<div v-if="element.type == 'repeater'">
																		<InputRepeater
																			:iterable="false"
																			:element-data="element"
																			:repeater-ref="`${step.id}-${element.id}`"
																			:ref="`${step.id}-${element.id}`"
																			v-model="data[element.id]"
																			@add="addHandler(element)"
																		/>
																	</div>
																	<!-- Divider Element -->
																	<div v-else-if="element.type == 'DividerElement'">
																		<DividerElement :text="element.text" />
																	</div>
																	<div v-else-if="element.type == 'HiddenElement'" class="hidden-response" >
																		<span class="question">{{ titleCaseID(element.id) }}:</span>
																		<span class="answer">{{ element.value }}</span>
																	</div>
																	<div v-else-if="element.type =='EitherOrAddressElement'">
																		<div class="fade-cover">
																			<div class="answer" v-if="element.id && element.type">
																				<span
																					class="question"
																					style="display: block;  margin-bottom: 1rem; color: #006d8e;"
																				>
																					{{ element.label }}
																				</span>
																				<AddressElement
																					:ref="`${step.id}-${element.id}`"
																					:element-data="element"
																					v-model="data[element.id]"
																					@error="onError"
																				/>
																			</div>
																		</div>
																	</div>
																	<div v-else-if="element.type == 'AddressElement'">
																		<div class="fade-cover">
																			<div class="answer" v-if="element.id && element.type">
																				<span
																					class="question"
																					style="display: block;  margin-bottom: 1rem; color: #006d8e;"
																				>
																					{{ element.label }}
																				</span>
																				<AddressElement
																					:ref="`${step.id}-${element.id}`"
																					:element-data="element"
																					v-model="data[element.id]"
																					@error="onError"
																				/>
																			</div>
																		</div>
																	</div>
																	<div v-else>
																		<div class="fade-cover">
																			<div class="answer" v-if="element.id && element.type">
																				<component
																					:all-form-data="data"
																					:ref="`${step.id}-${element.id}`"
																					:is="element.type"
																					:element-data="element"
																					:final-step="true"
																					v-model="data[element.id]"
																					@error="onError"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-else class="final-confirmation-check">
													<div v-for="(finalStep, index) in group.steps" :key="index" >
														<div v-for="(finalStepContent, index) in finalStep.content" :key="index">
															<div v-for="(finalStepElement, index) in finalStepContent.elements" :key="index">
																<component
																	:all-form-data="data"
																	:ref="`${step.id}-${finalStepElement.id}`"
																	:is="finalStepElement .type"
																	:element-data="finalStepElement "
																	v-model="data[finalStepElement.id]"
																	@error="onError"
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab-content>
				<template slot="footer" slot-scope="props" >
					<!--
					<div class=wizard-footer-left>
						<wizard-button
							v-if="props.activeTabIndex > 0 && !props.isLastStep"
							:style="props.fillButtonStyle"
						>
							Previous
						</wizard-button>
					</div>
					-->
					<div class="wizard-footer-right">
						<wizard-button
							tablist="0"
							v-if="props.isLastStep"
							class="wizard-footer-right"
							:style="props.fillButtonStyle"
							@click.native="storeData(); finalValidate(currentStep);"
						>
							Submit
						</wizard-button>
						<!--
						<wizard-button
							@click.native="alert('Done')"
							class="wizard-footer-right finish-button"
							:style="props.fillButtonStyle"
						>
							{{props.isLastStep ? 'Done' : 'Next'}}
						</wizard-button>
						-->
					</div>
				</template>
			</form-wizard>
		</form>
		<pre style="text-align: left">{{ prettyData }}</pre>
	</div>
</template>
