import Vue from "vue";
import VCalendar from 'v-calendar';
import VueRouter from "vue-router";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import App from './App.vue';
import PDFSubmission from './components/PDFSubmission.vue';
import * as Actions from "./hooks/actions";
import * as Filters from "./hooks/filters";
import FormBuilder from './views/FormBuilder.vue';
import SubmissionError from './views/SubmissionError.vue';
import ThankYou from './views/ThankYouConfirmation.vue';
// import store from './store'
// import { createMemoryHistory } from "vue-router";

Vue.use(VueFormWizard);
Vue.use(VCalendar);
Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.prototype.$formData = FormData;
Vue.prototype.$formErrors = {};
Vue.prototype.$filters = Filters;
Vue.prototype.$actions = Actions;
// Vue.prototype.$store = store;

const formName = document.getElementById('cfw-form-builder')?.getAttribute('form-name');

if ( formName !== undefined && formName ) {

    fetch('/assets/form-data/' + formName + '.json ').then( (v) => v.json() ).then( (v) => {
        Vue.config.productionTip = false;
        Vue.prototype.$formData = v;
        Vue.prototype.$formErrors = {};
        Vue.prototype.$filters = Filters;
        Vue.prototype.$actions = Actions;

        const router = new VueRouter({
            mode: "hash",
            base: __dirname,
            routes: [
                {
                    path: "/",
                    component: FormBuilder
                },
                {
                    path: "/thank-you-confirmation",
                    component: ThankYou
                },
                {
                    path: '/submission-error',
                    component: SubmissionError
                },
                {
                    path: "/pdf-test",
                    name: "Test",
                    component: PDFSubmission,
                }
            ],
            scrollBehavior(to, from, savedPosition) {
                // always scroll to top
                return { top: 0 }
            },
        });

        new Vue({
            router,
            // store,
            render: (h) => h(App),
        }).$mount("#cfw-form-builder");
    });


}

