<script>

import VueHtml2pdf from 'vue-html2pdf'

export default {
	components: {
		VueHtml2pdf
	},

	data: function() {
		return {
			template: {},
			collectedData: {},
			// extraEmailHtml: [],
			extraPdfHtml: [],
			sendCsrEmail: false,
		}
	},

	mounted: function() {
		this.template = this.getTemplate();
		this.collectedData = this.getData();
		this.elementConditionals = this.getElementConditionals();
		this.stepConditionals = this.getStepConditionals();
		// this.extraEmailHtml = this.getExtraEmailHtml();
		this.extraPdfHtml = this.getExtraPdfHtml();
		this.sendCsrEmail = typeof this.$filters.csrHtml !== 'undefined' ? true : false;

		this.generateReport();
	},

	methods: {
		currentDate: function() {
			const current = new Date();
			const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`
			return date
		},

		generateReport: function() {
			this.$refs.html2Pdf.generatePdf();
		},

		getTemplate: function() {
			const storedData = JSON.parse(localStorage.getItem("template"));
			return storedData;
		},

		getFileName: function() {
			const storedData = JSON.parse(localStorage.getItem("template"));
			return storedData.name.replace( /\s/g, '-' );
		},

		getElementConditionals: function() {
			return JSON.parse(localStorage.getItem("elementConditionals"));
		},

		/**
		 * Parses hooks.filter values in form json to pass to for customer and csr emails
		 *
		 * @function getExtraEmailHtml()
		 *
		 * JSON form examples (Array or Object):
		 * @example
		 * [
		 * 	{
		 *		"filter": "csrHtml",
		 *		"callback": "",
		 *		"html": "<i>Customer Service Representative notes...</i>"
		 *	},
		 *	{
		 *		"filter": "extraHtml",
		 *		"callback": "authorizedPropertyAddresses"
		 *	}
		 * ]
		 * @example
		 * {
		 * 		"filter": "extraHtml",
		 *		"callback": "exampleCallbackFunction"
		 * }
		 * @example
		 * {
		 * 		"filter": "extraHtml",
		 *		"html": "Example html"
		 * }
		 * @returns
		 * [
		 * 		extraHtml: "Example html",
		 *  	csrHtml: "<b>Example html</b>"
		 * ]
		 */

		getExtraEmailHtml: function() {
			const finalStep = this.$formData.groups.find((item) => !!item?.finalStep);
			const filters = finalStep?.hooks?.filter;
			const formFilters = this.$filters;

			function filterFunction(filter, callback, html) {
				if (!!callback) {
					return formFilters[callback]();
				} else {
					return formFilters[filter](html);
				}
			}

			if (filters instanceof Array) {
				return filters.map((f) => {
					if (!f?.filter) return false;

					return { [f.filter] : filterFunction(f.filter, f?.callback, f?.html) }
				});
			}

			if (filters instanceof Object) {
				if (!filters?.filter) return [];

				const callback = filters?.callback || filters.filter;

				return [{ [filters.filter]: this.$filters[callback](filters?.html) || '' }];
			}

			return [];
		},

		getExtraPdfHtml: function() {
			const pdfStuff = this.$formData.pdfElements;
			const filters = pdfStuff?.hooks?.filter;

			const formFilters = this.$filters;

			function filterFunction(filter, callback, html) {
				if (!!callback) {
					return formFilters[callback]();
				} else {
					return formFilters[filter](html);
				}
			}

			if (filters instanceof Array) {
				return filters.map((f) => {
					if (!f?.filter) return false;

					return { [f.filter] : filterFunction(f.filter, f?.callback, f?.html) }
				});
			}

			if (filters instanceof Object) {
				if (!filters?.filter) return [];

				const callback = filters?.callback || filters.filter;

				return [{ [filters.filter]: this.$filters[callback](filters?.html) || '' }];
			}

			if (filters) {
				const retval = filterFunction(filters, false, false );
				return retval;
			}

			return [];
		},


		getStepConditionals: function() {
			return JSON.parse(localStorage.getItem("stepConditionals"));
		},

		getData: function() {
			const storedData = JSON.parse(localStorage.getItem("data"));
			return storedData;
		},

		getPDF: function() {
			const storedData = localStorage.getItem("pdfFile");
			return storedData;
		},

		isActiveStep: function(step) {
			if (!step.conditional) {
				return true;
			} else if (step.conditional) {
				for (const conditional of step.conditional ) {
					const conditionalKey = Object.keys(conditional)[0];
					if (conditionalKey in this.stepConditionals) {
						if (this.stepConditionals[conditionalKey] == 'active') {
							return true;
						} else {
							return false;
						}
					}
				}
			} else {
				return true
			}
		},

		isActiveElement: function(element) {
			if (!element.conditional) {
				return true;
			} else if (element.conditional) {
				for (const conditional of element.conditional ) {
					const conditionalKey = Object.keys(conditional)[0];

					if (conditionalKey in this.elementConditionals) {
						if (this.elementConditionals[conditionalKey] == 'active') {
							return true;
						} else {
							return false;
						}
					}
				}
			} else {
				return true;
			}
		},

		linkData: function(templateId) {
			for (const dataId in this.collectedData) {
				if (dataId == templateId) {
					return dataId;
				}
			}
		},

		beforeDownload: function(e) {
			const worker = e.html2pdf();  // Or: const worker = new html2pdf.Worker;
			const self = this;

			worker.set(e.options).from(e.pdfContent).toPdf().get('pdf').then(function (pdfObj) {
				const pdfBlob = pdfObj.output('blob');
				const formData = new FormData();

				formData.append('file', pdfBlob, e.options.filename);

				const request = new XMLHttpRequest();

				request.open("POST", process.env.VUE_APP_RESPONDER_URL + 'upload-file.php');
				request.send(formData);
				request.onreadystatechange = function() {
					if (request.readyState === 4) {
						if (request.status === 200) {
							const response = JSON.parse(request.responseText);
							localStorage.setItem("pdfFile", response.filename);
							self.sendEmail();
							self.sendCsrEmail && self.sendEmailCsr();
						} else {
							console.log('Failed to upload form PDF');
						}
					}
				}
			});
		},

		sendEmail: function() {
			const formData = new FormData();

			formData.append("template", JSON.stringify(this.getTemplate()));
			formData.append("collected-data", JSON.stringify(this.collectedData));
			formData.append("pdfFile", this.getPDF());

			const extraEmailHtml =  this.getExtraEmailHtml();
			const html = extraEmailHtml.find((item) => item.hasOwnProperty("extraHtml"));
			html?.extraHtml && formData.append('extraHtml', html.extraHtml);

			// formData.append("id", 7878);
			var request = new XMLHttpRequest();
			request.open("POST", process.env.VUE_APP_RESPONDER_URL + 'email.php');
			request.send(formData);
			request.onreadystatechange = function () {
				if (request.readyState === 4) {
					if (request.status === 200) {
						console.log('successfully sent email');
					} else {
						console.log('failed to send email');
					}
				}
			}
		},

		sendEmailCsr: function() {
			var formData = new FormData();
			formData.append("template", JSON.stringify(this.getTemplate()));
			formData.append("collected-data", JSON.stringify(this.getData()));
			formData.append("pdfFile", this.getPDF());

			const extraEmailHtml =  this.getExtraEmailHtml();
			const html = extraEmailHtml.find((item) => item.hasOwnProperty("csrHtml"));
			html?.csrHtml && formData.append('csrHtml', html.csrHtml);

			// formData.append("id", 7878);
			var request = new XMLHttpRequest();
			request.open("POST", process.env.VUE_APP_RESPONDER_URL + 'email-csr.php');
			request.send(formData);
			request.onreadystatechange = function () {
				if (request.readyState === 4) {
					if (request.status === 200 ) {
						console.log('successfully sent csr email');
					} else {
						console.log('failed to send csr email');
					}
				}
			}
		}
	},
}
</script>

<template>
  	<div>
		<!-- pre style="text-align: left">{{ template[ 'thankYouElements' ] }}</pre -->
	  	<VueHtml2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="false"
			:preview-modal="false"
			:paginate-elements-by-height="750"
			margin=".75in"
			:filename="getFileName()"
			:pdf-quality="2"
			:manual-pagination="false"
			pdf-format="letter"
			pdf-orientation="portrait"
			pdf-content-width="8.5in"
			pdf-content-height="11in"
			@beforeDownload="beforeDownload($event)"
			ref="html2Pdf"
	  	>
			<section id="pdf-content" slot="pdf-content">
			  	<section class="pdf-item">
					<div class="pdf-header">
						<div>
							<div class="date">{{ currentDate() }}</div>
							<h1 class="form-title">
								{{ template.name }}
							</h1>
						</div>
						<img class="city-logo" src="https://utilities.cityoffortwayne.org/wp-content/uploads/2021/01/CityUtilitiesLogo_FullColor.png" alt="City Utilities 'Water That Works'">
					</div>
					<div class="group" v-for="(group, index) in template.groups" :key="index">
						<h2 class="group-title">{{ group.title }}</h2>
						<div class="step" v-for="(step, index) in group.steps" :key="index">
							<div v-if="isActiveStep(step)">
								<div v-if="!step.endForm">
									<div class="content" v-for="(content, index) in step.content" :key="index">
										<!-- eslint-disable vue/no-v-html -->
										<p class="question" v-html="content.text"></p>
										<div v-for="(element, index) in content.elements" :key="index">
											<div v-if="isActiveElement(element)">
												<!-- {{ element }} -->
												<div class="name" v-if="element.type == 'NameElement' ">
													<span class="label">Name: {{collectedData[element.id].firstName}} {{collectedData[element.id].middleInitial}} {{collectedData[element.id].lastName}}</span>
												</div>

												<div class="hidden-response" v-if="element.type == 'HiddenElement'">
													<span style="text-transform: capitalize;">{{ element.value }}</span>
												</div>

												<div class="phone-element" v-if="element.type == 'PhoneElement'">
													<span class="label">{{ element.label }}:</span>
													<span> {{collectedData[element.id] ? collectedData[element.id] : "No phone number provided" }} </span>
												</div>

												<div class="text-element" v-if="element.type == 'TextElement'">
													<span class="label">{{ element.label }}:</span>
													<span> {{collectedData[element.id]}} </span>
												</div>

												<div class="email-element" v-if="element.type == 'EmailElement'">
													<span class="label">{{ element.label }}:</span>
													<span> {{collectedData[element.id]}} </span>
												</div>

												<div class="radio-element" v-if="element.type == 'RadioElement'">
													<span class="label">{{ element.label }}:</span>
													<span> {{collectedData[element.id]}} </span>
												</div>

												<div class="address-element" v-if="element.type == 'AddressElement' || element.type == 'EitherOrAddressElement'">
													<span class="label">{{ element.label }}</span>

													<div v-if="collectedData[element.id].alternateAddress">
														<div class="street-address"><span>{{collectedData[element.id].alternateAddress}}</span></div>
													</div>
													<div v-else>
														<div class="street-address"><span>{{collectedData[element.id].streetAddress}}</span></div>
														<div class="second-address"><span>{{collectedData[element.id].secondAddress}}</span></div>
														<div class="csz">
															<span class="city">{{collectedData[element.id].city}}</span>,
															<span class="state">{{collectedData[element.id].state}}</span>&nbsp;
															<span class="zip">{{collectedData[element.id].zip}}</span>
														</div>
													</div>
												</div>

												<div class="calendar-element" v-if="element.type == 'CalendarElement'">
													<span class="label">{{ element.label }}</span>
													<span> {{ collectedData[element.id] }}</span>
												</div>

												<div class="checkbox-element" v-if="element.type == 'CheckboxElement'">
													<p>{{ element.checkboxButtons[0].text }}</p>
												</div>

												<div class="repeater-element" v-if="element.type == 'repeater'">
												<!--
													This loop was incomplete.
													It was counting 1 template, instead of multiple occurences, so it was only ever index 0
													There needed to be a loop inside of a loop that looped over the repeater data
												-->
													<div v-for="(repeaterElement, repeaterIndex) in element.elements" :key="repeaterIndex">
														<div v-for="(repeaterData, dataIndex) in collectedData[element.id]" :key="dataIndex">

															<div class="repeater-address-element space-below" v-if="repeaterElement.type == 'AddressElement'">
																<div class="label">{{ repeaterElement.label }} ({{ dataIndex }})</div>
																<div class="street-address">{{repeaterData[repeaterElement.id].streetAddress}}</div>
																<div class="second-address">{{repeaterData[repeaterElement.id].secondAddress}}</div>
																<div class="csz">
																	<span class="city">{{repeaterData[repeaterElement.id].city}}</span>,
																	<span class="state">{{repeaterData[repeaterElement.id].state}}</span>&nbsp;
																	<span class="zip">{{repeaterData[repeaterElement.id].zip}}</span>
																</div>
															</div>

															<div class="repeater-file-attach-element space-below" v-if="repeaterElement.type == 'FileAttachElement'">
																<div class="label">{{ repeaterElement.label }} ({{ dataIndex }})</div>
																{{repeaterData[repeaterElement.id].names[0]}}
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="extraPdfHtml" v-html="extraPdfHtml"></div>
				</section>
		  	</section>
	  	</VueHtml2pdf>

		<!-- <button class="download-button" @click="generateReport()">Download PDF</button> -->
<!--
		<router-link
			class="back-link"
			:to="'/'"
		>
			Back to Form
		</router-link> -->
  	</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

div {
	display: block;
	line-height: 1.2;
}

/* div span {
	margin-bottom: 0.25rem !important;
} */

div p {
	/* margin-bottom: 0.5rem !important; */
	line-height: 1.2;
}

#pdf-content {
	padding: 2rem !important;
	font-family: 'Roboto', sans-serif;
}

.content {
	margin-bottom: 1rem !important;
}

.label {
	font-weight: 500;
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.name, .hidden-response, .phone-element, .text-element, .email-element, .checkbox-element, .repeater-element, .address-element {
	margin-bottom: 0.125in;
}

div.street-address, div.second-address {
	margin-bottom: 0 !important;
}

.content div:has( .label ) {
	margin-bottom: 0 !important;
}

.pdf-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: -2em;
}

.form-title {
	font-size: 24pt;
}

.city-logo {
	height: 1in;
	width: auto;
}

h1 {
	color: #006D8E;
}

.question {
	font-size: .8em;
	font-style: italic;
}

.group {
	page-break-inside: avoid;
	padding-top: 2em;
}

.group-title {
	background-color: #006D8E;
	color: white;
	padding: 0.25rem 1rem;
	page-break-before: auto;
	margin-bottom: 1rem;
}

.space-below {
	margin-bottom: 1em;
}

</style>
